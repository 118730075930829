//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Translator       from '@helper/Translator';
import { UserActions }  from '@slices/user';
import Button           from '@stateless/atomic/Button';
import ContentWrapper   from '@stateless/atomic/ContentWrapper';
import LabeledTextInput from '@stateless/atomic/LabeledTextInput';
import TextInputType    from '@stateless/atomic/TextInput/TextInputType';
import PageTitle        from '@stateless/composed/PageTitle';
import { selectUser }   from '@store/selectors/user';

import styles from './styles.module.scss';

const Login = () => {
    const translator = new Translator('components.login.');
    const dispatch   = useDispatch();
    const user       = useSelector(selectUser);

    function onEmailChange(event) {
        dispatch(UserActions.setUsername({
            username: event.target.value,
        }));
    }

    function onPasswordChange(event) {
        dispatch(UserActions.setPassword({
            password: event.target.value,
        }));
    }

    function onLoginButtonClick(event) {
        event.preventDefault();
        dispatch(UserActions.login());
    }

    return (
        <>
            <PageTitle title={I18n.t('login')} />
            <ContentWrapper>
                <div className={styles.loginScreen}>
                    <form
                        onSubmit={onLoginButtonClick}
                        className={styles.loginForm}
                    >
                        <LabeledTextInput
                            required={true}
                            value={user.username}
                            labelText={translator.translate('emailLabel')}
                            onChange={onEmailChange}
                        />
                        <LabeledTextInput
                            required={true}
                            onChange={onPasswordChange}
                            value={user.password}
                            labelText={translator.translate('passwordLabel')}
                            type={TextInputType.password}
                        />
                        <Button
                            text={translator.translate('login')}
                            onClick={onLoginButtonClick}
                        />
                    </form>
                </div>
            </ContentWrapper>
        </>
    );
};

export default Login;
