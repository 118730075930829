//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

const basePath = [
    'orders',
];

export const selectOrders = (state) => {
    return _.get(state, [...basePath, 'orders'], []);
};

export const selectOrderDate = (state) => {
    return _.get(state, [...basePath, 'selectedDate'], new Date());
};

export const selectShowServedOrders = (state) => {
    return _.get(state, [...basePath, 'showServedOrders'], false);
};
