//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import SagaStateHelper               from '@helper/SagaStateHelper';
import { AdditionalRequestsActions } from '@slices/additionalRequests';
import { AllergensActions }          from '@slices/allergens';
import { AppSettingsActions }        from '@slices/appSettings';
import { OrdersActions }             from '@slices/orders';
import { ProductAdditionsActions }   from '@slices/productAdditions';
import { ProductsActions }           from '@slices/products';
import { RestaurantsActions }        from '@slices/restaurants';
import { TimeSlotsActions }          from '@slices/timeSlots';
import { UserActions }               from '@slices/user';
import selectToken                   from '@store/selectors/user';

function* preLoadData() {
    yield put(UserActions.tryRestoreToken());
    yield put(ProductsActions.fetchProducts());
    yield put(AppSettingsActions.fetchAppSettings());
    yield put(AllergensActions.fetchAllergens());
    yield put(AdditionalRequestsActions.fetchAdditionalRequests());
    yield put(ProductAdditionsActions.fetchProductAdditions());
    yield put(TimeSlotsActions.fetchTimeSlots());
    yield put(RestaurantsActions.fetchRestaurants());

    const token = yield SagaStateHelper.selectBySelector(selectToken);

    if (token) {
        yield put(UserActions.fetchUser());
        yield put(OrdersActions.fetchOrdersInInterval());
    }
}

export default {
    preLoadData,
};
