//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { CODE_ENTER }  from 'keycode-js';
import { CODE_ESCAPE } from 'keycode-js';

export default class KeyboardEvent {
    static containsKey = (event, key) => {
        return [event.key, event.code].includes(key);
    };

    static isEnterKey = (event) => {
        return KeyboardEvent.containsKey(event, CODE_ENTER);
    };

    static isEscape(event) {
        return KeyboardEvent.containsKey(event, CODE_ESCAPE);
    }
}
