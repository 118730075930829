//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes                     from '@components/PropTypes';
import { VisibleOrderProductStatus } from '@constants/OrderProductStatus';
import ProductType                   from '@constants/ProductType';
import Workplace                     from '@constants/Workplace';
import OrderDisplayManager           from '@helper/OrderDisplayManager';
import Translator                    from '@helper/Translator';
import Price                         from '@stateless/atomic/Price';
import PriceSize                     from '@stateless/atomic/Price/PriceSize';
import Spacer                        from '@stateless/atomic/Spacer';
import StatusButton                  from '@stateless/atomic/StatusButton';

import styles from './styles.module.scss';

const propTypes = {
    isToday:                  PropTypes.bool,
    order:                    PropTypes.object,
    orderProduct:             PropTypes.object,
    updateOrderProductStatus: PropTypes.func,
    workplace:                PropTypes.string,
};

const OrderProductListItem = ({
    order                    = null,
    isToday                  = false,
    orderProduct             = null,
    updateOrderProductStatus = _.noop,
    workplace                = '',
}) => {
    const translator = new Translator('components.orderProductListItem.');

    function renderExtras() {
        return _.map(orderProduct.productAdditions, (extra) => {
            const key = `${orderProduct.iri}-${extra.iri}`;

            return (
                <div key={key}>
                    {extra.title}
                </div>
            );
        });
    }

    function renderStatusBar() {
        if (
            workplace === Workplace.kitchen &&
            orderProduct.product.type === ProductType.drink
        ) {
            return null;
        }

        return _.map(Object.keys(VisibleOrderProductStatus), (key) => {
            const listItemKey       = `${orderProduct.iri}-${key}`;
            const statusButtonColor = OrderDisplayManager.getOrderPositionStatusButtonColor(key);
            const disabled          = !isToday ||
                (
                    workplace === Workplace.checkout &&
                    orderProduct.product.type === ProductType.pizza
                );

            function onStatusButtonClicked(event) {
                event.stopPropagation();
                updateOrderProductStatus(order.iri, orderProduct.iri, key);
            }

            return (
                <StatusButton
                    disabled={disabled}
                    key={listItemKey}
                    onClick={onStatusButtonClicked}
                    active={orderProduct.status === key}
                    text={translator.translate(key)}
                    color={statusButtonColor}
                />
            );
        });
    }

    function renderPrice() {
        if (workplace === Workplace.checkout) {
            return (
                <Price
                    value={orderProduct.positionPrice}
                    size={PriceSize.small}
                />
            );
        }

        return null;
    }

    function renderPositionComment() {
        if (!orderProduct.customerComment) {
            return null;
        }

        return (
            <div>
                {orderProduct.customerComment}
            </div>
        );
    }

    return (
        <>
            <div className={styles.orderProductListItem}>
                <div className={styles.positionDetails}>
                    <div>
                        {orderProduct.product.name}
                    </div>
                    <div>
                        {orderProduct.quantity}
                    </div>
                    <div>
                        {renderExtras()}
                        {renderPositionComment()}
                    </div>
                </div>
                <div>
                    {renderPrice()}
                </div>
                <div
                    className={classNames(
                        styles.orderProductStatusBar,
                        styles.right,
                    )}
                >
                    {renderStatusBar()}
                </div>
            </div>
            <Spacer
                line={true}
                height={10}
            />
        </>
    );
};

OrderProductListItem.propTypes = propTypes;

export default OrderProductListItem;
