//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import OrderProductStatus         from '@constants/OrderProductStatus';
import OrderStatus                from '@constants/OrderStatus';
import Overlay                    from '@constants/Overlay';
import ProductType                from '@constants/ProductType';
import { OrdersActions }          from '@slices/orders';
import { OverlayActions }         from '@slices/overlay';
import StatelessHeader            from '@stateless/composed/Header';
import { selectActiveAppSetting } from '@store/selectors/appSettings';
import { selectOrders }           from '@store/selectors/orders';
import { selectOrderDate }        from '@store/selectors/orders';
import { selectWorkplace }        from '@store/selectors/user';

const propTypes = {};

const Header = () => {
    const dispatch              = useDispatch();
    const orders                = useSelector(selectOrders);
    const selectedDate          = useSelector(selectOrderDate);
    const selectedWorkplace     = useSelector(selectWorkplace);
    const appSetting            = useSelector(selectActiveAppSetting);
    const soldOutTill           = appSetting?.soldOutTill;
    const numberOfPendingPizzas = useMemo(
        () => {
            let numberOfPizzas = 0;

            const filteredOrders = _.filter(orders, (order) => {
                return (
                    order.status === OrderStatus.PENDING ||
                    order.status === OrderStatus.IN_PROGRESS
                );
            });

            _.forEach(filteredOrders, (order) => {
                _.forEach(order.orderProducts, (orderProduct) => {
                    if (
                        orderProduct.product.type === ProductType.pizza &&
                        (
                            orderProduct.status === OrderProductStatus.PENDING ||
                            orderProduct.status === OrderProductStatus.IN_OVEN ||
                            orderProduct.status === OrderProductStatus.IN_REFINING
                        )
                    ) {
                        numberOfPizzas += orderProduct.quantity;
                    }
                });
            });

            return numberOfPizzas;
        },
        [orders],
    );

    function onDateChanged(date) {
        const middleOfDay = new Date(date);

        middleOfDay.setHours(12, 0, 0, 0);

        dispatch(OrdersActions.setSelectedDate({
            selectedDate: middleOfDay,
        }));
    }

    function onMenuOpenClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.menu,
        }));
    }

    return (
        <StatelessHeader
            numberOfPendingPizzas={numberOfPendingPizzas}
            selectedDate={selectedDate}
            onDateChanged={onDateChanged}
            onMenuOpenClicked={onMenuOpenClicked}
            workplace={selectedWorkplace}
            deliveryEnabled={appSetting?.deliveryEnabled}
            soldOutTill={soldOutTill}
        />
    );
};

Header.propTypes = propTypes;

export default Header;
