//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    productAdditions: [],
});

const productAdditionsSlice = createSlice({
    name:     'productAdditions',
    initialState,
    reducers: {
        fetchProductAdditions:          LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchProductAdditionsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchProductAdditionsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                productAdditions: {
                    $set: action.payload.productAdditions,
                },
            });
        }),
    },
});

export const ProductAdditionsActions = productAdditionsSlice.actions;

export const ProductAdditionsReducer = productAdditionsSlice.reducer;

export default productAdditionsSlice;
