//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

const initialState = Object.freeze({
    orders:           [],
    selectedDate:     new Date(),
    showServedOrders: false,
});

function updateOrderOnPosition(state, action) {
    const order = _.get(action, 'payload.order', null);

    if (!order) {
        return state;
    }

    const orderIndex = _.findIndex(state.orders, (orderItem) => {
        return orderItem.iri === order.iri;
    });

    if (orderIndex === -1) {
        return state;
    }

    return update(
        state,
        {
            orders: {
                [orderIndex]: {
                    $set: order,
                },
            },
        },
    );
}

const ordersSlice = createSlice({
    name:     'orders',
    initialState,
    reducers: {
        fetchOrdersInInterval: _.noop,
        fetchOrders:           _.noop,
        fetchOrdersSucceeded:  (state, action) => {
            const orders = _.get(action, 'payload.orders', []);

            return update(
                state,
                {
                    orders: {
                        $set: orders,
                    },
                },
            );
        },
        updateOrder:           _.noop,
        updateOrderSucceeded:  updateOrderOnPosition,
        fetchOrder:            _.noop,
        fetchOrderSucceeded:   updateOrderOnPosition,
        setSelectedDate:       (state, action) => {
            const selectedDate = _.get(action, 'payload.selectedDate', new Date());

            return update(
                state,
                {
                    selectedDate: {
                        $set: selectedDate,
                    },
                },
            );
        },
        setShowServedOrders:   (state, action) => {
            return update(
                state,
                {
                    showServedOrders: {
                        $set: action.payload.showServedOrders,
                    },
                },
            );
        },
    },
});

export const OrdersActions = ordersSlice.actions;

export const OrdersReducer = ordersSlice.reducer;

export default ordersSlice;
