//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// eslint-disable-next-line rulesdir/format-import-linter -- eslint wants to format this import
import 'react-notifications/lib/notifications.css';

import React from 'react';

import classNames            from 'classnames';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';

import PropTypes            from '@components/PropTypes';
import NotificationPosition from '@stateless/composed/NotificationHandler/NotificationPosition';

import styles from './styles.module.scss';

const propTypes = {
    position: PropTypes.oneOfObjectValues(NotificationPosition),
};

const NotificationHandler = ({
    position = NotificationPosition.right,
}) => {
    const wrapperClassNames = classNames(
        styles.notificationHandler,
        {
            [styles.notificationHandlerLeft]:   position === NotificationPosition.left,
            [styles.notificationHandlerCenter]: position === NotificationPosition.center,
        },
    );

    return (
        <div className={wrapperClassNames}>
            <NotificationContainer />
        </div>
    );
};

NotificationHandler.propTypes = propTypes;

export default NotificationHandler;
