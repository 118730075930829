//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import LoadingLevel       from '@constants/LoadingLevel';
import { LoadingActions } from '@slices/loading';

function loadingMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        const loadingLevel = _.get(action, 'payload.loadingLevel');

        switch (loadingLevel) {
            case LoadingLevel.increase:
                dispatch(LoadingActions.increaseLevel({
                    loadingAction: action,
                }));

                break;
            case LoadingLevel.decrease:
                dispatch(LoadingActions.decreaseLevel({
                    loadingAction: action,
                }));

                break;
            default:
                break;
        }

        return next(action);
    };
}

export default loadingMiddleware;
