//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes   from '@components/PropTypes';
import Icon        from '@stateless/atomic/Icon';
import IconType    from '@stateless/atomic/Icon/IconType';
import OverlaySize from '@stateless/atomic/Overlay/OverlaySize';

import styles from './styles.module.scss';

const propTypes = {
    children:     PropTypes.children,
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
    size:         PropTypes.oneOfObjectKeys(OverlaySize),
};

const Overlay = ({
    open         = false,
    onCloseClick = _.noop,
    children     = null,
    size         = null,
}) => {
    const overlayRef = React.useRef(null);

    useEffect(() => {
        if (open) {
            overlayRef.current.focus();
        }
    }, [open]);

    return (
        <div
            ref={overlayRef}
            tabIndex={0}
            className={classNames(
                styles.overlayWrapper,
                {
                    [styles.overlayHidden]: !open,
                    [styles.fullScreen]:    size === OverlaySize.fullScreen,
                    [styles.wide]:          size === OverlaySize.wide,
                    [styles.small]:         size === OverlaySize.small,
                },
            )}
        >
            <div className={styles.overlayContainer}>
                <div className={styles.overlayHeader}>
                    <div className={styles.overlayCloseButton}>
                        <Icon
                            iconType={IconType.cross}
                            onClick={onCloseClick}
                        />
                    </div>
                </div>
                <div className={styles.overlayContent}>
                    {children}
                </div>
            </div>
        </div>
    );
};

Overlay.propTypes = propTypes;

export default Overlay;
