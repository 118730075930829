//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    allergens: [],
});

const allergensSlice = createSlice({
    name:     'allergens',
    initialState,
    reducers: {
        fetchAllergens:          LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchAllergensFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchAllergensSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                allergens: {
                    $set: action.payload.allergens,
                },
            });
        }),
    },
});

export const AllergensActions = allergensSlice.actions;

export const AllergensReducer = allergensSlice.reducer;

export default allergensSlice;
