//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes         from '@components/PropTypes';
import StatusButtonColor from '@stateless/atomic/StatusButton/StatusButtonColor';

import styles from './styles.module.scss';

const propTypes = {
    active:   PropTypes.bool,
    color:    PropTypes.oneOfObjectKeys(StatusButtonColor),
    disabled: PropTypes.bool,
    onClick:  PropTypes.func,
    text:     PropTypes.string,
};

const StatusButton = ({
    disabled = false,
    onClick  = null,
    active   = false,
    text     = '',
    color    = StatusButtonColor.gold,
}) => {
    const [pressed, setPressed] = React.useState(false);

    function onMouseDown() {
        setPressed(true);
    }

    function onMouseUp() {
        setPressed(false);
    }

    return (
        <button
            onTouchStart={onMouseDown}
            onTouchEnd={onMouseUp}
            onClick={onClick}
            disabled={disabled}
            className={classNames(
                styles.statusButton,
                styles[color],
                {
                    [styles.active]:  active,
                    [styles.pressed]: pressed,
                },
            )}
        >
            {text}
        </button>
    );
};

StatusButton.propTypes = propTypes;

export default StatusButton;
