//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';
import Currency  from '@constants/Currency';
import Languages from '@constants/Languages';

import PriceColor from './PriceColor';
import PriceSize  from './PriceSize';
import styles     from './styles.module.scss';

const propTypes = {
    color:     PropTypes.oneOfObjectKeys(PriceColor),
    currency:  PropTypes.oneOfObjectKeys(Currency),
    emptyText: PropTypes.string,
    size:      PropTypes.oneOfObjectKeys(PriceSize),
    value:     PropTypes.number,
};

const Price = ({
    color     = PriceColor.black,
    currency  = Currency.euro,
    size      = PriceSize.default,
    value     = 0,
    emptyText = '-',
}) => {
    function renderPrice() {
        if (!value) {
            return emptyText;
        }

        return new Intl.NumberFormat(
            Languages.german,
            {
                style: 'currency',
                currency,
            },
        ).format(value);
    }

    return (
        <div
            className={classNames(
                styles.price,
                {
                    [styles.orange]: color === PriceColor.orange,
                    [styles.white]:  color === PriceColor.white,
                    [styles.small]:  size === PriceSize.small,
                },
            )}
        >
            {renderPrice()}
        </div>
    );
};

Price.propTypes = propTypes;

export default Price;
