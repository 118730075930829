//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import PageVisibility  from 'react-page-visibility';
import { useDispatch } from 'react-redux';

import EventListenerEffect from '@helper/EventListener';
import { useWindow }       from '@slices/window';

const WindowVisibilityWatcher = () => {
    const dispatch      = useDispatch();
    const windowActions = useWindow(dispatch);

    function handleVisibilityChange(isVisible) {
        if (isVisible) {
            windowActions.windowBecameVisible();
        } else {
            windowActions.windowBecameHidden();
        }
    }

    function onBlur() {
        windowActions.windowLostFocus();
    }

    function onFocus() {
        windowActions.windowGotFocus();
    }

    useEffect(() => {
        return EventListenerEffect.subscribeAndRemove([
            EventListenerEffect.eventWithCallback('blur', onBlur),
            EventListenerEffect.eventWithCallback('focus', onFocus),
        ]);
    }, []);

    return (
        <PageVisibility
            onChange={handleVisibilityChange}
        />
    );
};

export default WindowVisibilityWatcher;
