//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    allergy:      'allergy',
    arrowLeft:    'arrowLeft',
    calendar:     'calendar',
    checked:      'checked',
    checkout:     'checkout',
    cash:         'cash',
    chevronRight: 'chevronRight',
    cross:        'cross',
    delivery:     'delivery',
    drink:        'drink',
    forms:        'forms',
    info:         'info',
    instagram:    'instagram',
    menu:         'menu',
    minus:        'minus',
    edit:         'edit',
    vegan:        'vegan',
    flame:        'flame',
    note:         'note',
    pizza:        'pizza',
    plus:         'plus',
    unchecked:    'unchecked',
});
