//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import PropTypes                  from '@components/PropTypes';
import { AppSettingsActions }     from '@slices/appSettings';
import { OrdersActions }          from '@slices/orders';
import { OverlayActions }         from '@slices/overlay';
import { UserActions }            from '@slices/user';
import StatelessMenuOverlay       from '@stateless/composed/MenuOverlay';
import { selectActiveAppSetting } from '@store/selectors/appSettings';
import { selectShowServedOrders } from '@store/selectors/orders';
import { selectWorkplace }        from '@store/selectors/user';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const MenuOverlay = ({
    open         = false,
    onCloseClick = _.noop,
}) => {
    const dispatch          = useDispatch();
    const selectedWorkplace = useSelector(selectWorkplace);
    const appSetting        = useSelector(selectActiveAppSetting);
    const showServedOrders  = useSelector(selectShowServedOrders);
    const soldOutTill       = appSetting?.soldOutTill;

    function onWorkplaceSelected(workplace) {
        dispatch(UserActions.setWorkplace({
            workplace,
        }));
    }

    function onSetDeliveryEnabled() {
        dispatch(AppSettingsActions.updateAppSetting({
            iri:  appSetting?.iri,
            body: {
                deliveryEnabled: !appSetting?.deliveryEnabled,
            },
        }));
    }

    function onSetSoldOutTillClicked(newSoldOutTill) {
        return () => {
            dispatch(AppSettingsActions.updateAppSetting({
                iri:  appSetting?.iri,
                body: {
                    soldOutTill: newSoldOutTill,
                },
            }));
        };
    }

    function setShowServedOrders(value) {
        dispatch(OrdersActions.setShowServedOrders({
            showServedOrders: value,
        }));
    }

    function onLogoutClicked() {
        dispatch(OverlayActions.resetOverlays());
        dispatch(UserActions.logout());
    }

    return (
        <StatelessMenuOverlay
            open={open}
            onClose={onCloseClick}
            onWorkplaceSelected={onWorkplaceSelected}
            selectedWorkplace={selectedWorkplace}
            appSetting={appSetting}
            setDeliveryEnabled={onSetDeliveryEnabled}
            showServedOrders={showServedOrders}
            setShowServedOrders={setShowServedOrders}
            onLogoutClicked={onLogoutClicked}
            soldOutTill={soldOutTill}
            onSoldOutClicked={onSetSoldOutTillClicked}
        />
    );
};

MenuOverlay.propTypes = propTypes;

export default MenuOverlay;
