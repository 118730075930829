//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }        from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import SagaStateHelper                  from '@helper/SagaStateHelper';
import { NavigationActions }            from '@slices/navigation';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';

const stagingPassword = '7JvYVyjF9NuaxsvbbHoR';

function* authenticate() {
    const passwordFromState = yield SagaStateHelper.selectFromState('stagingAuthentication', 'password');
    const isAuthenticated   = stagingPassword === passwordFromState;

    yield put(StagingAuthenticationActions.authenticateSucceeded({
        isAuthenticated,
    }));
}

function* reset() {
    yield put(StagingAuthenticationActions.reset());
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], authenticate),
        // @formatter:on
    ];
};

export default {
    authenticate,
    reset,
    callSagas,
};
