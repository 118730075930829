//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';

import AdditionalRequestsSagas    from './additionalRequests';
import AllergensSagas             from './allergens';
import AppSettingsSagas           from './appSettings';
import LoadingSagas               from './loading';
import NavigationSagas            from './navigation';
import OrderProductsSagas         from './orderProducts';
import OrdersSagas                from './orders';
import PreLoadSagas               from './preload';
import ProductAdditionsSagas      from './productAdditions';
import ProductsSagas              from './products';
import RestaurantsSagas           from './restaurants';
import StagingAuthenticationSagas from './stagingAuthentication';
import TimeSlotsSagas             from './timeSlots';
import UserSagas                  from './user';

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE], LoadingSagas.rehydrate),
        takeLatest([REHYDRATE], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callRehydrateSagas(),
        ...callLocationChangeSagas(),
        ...StagingAuthenticationSagas.callSagas(),
        ...LoadingSagas.callSagas(),
        ...NavigationSagas.callSagas(),
        ...ProductsSagas.callSagas(),
        ...AppSettingsSagas.callSagas(),
        ...AllergensSagas.callSagas(),
        ...AdditionalRequestsSagas.callSagas(),
        ...ProductAdditionsSagas.callSagas(),
        ...TimeSlotsSagas.callSagas(),
        ...OrdersSagas.callSagas(),
        ...RestaurantsSagas.callSagas(),
        ...UserSagas.callSagas(),
        ...OrderProductsSagas.callSagas(),
    ]);
}

export default {
    root,
};
