//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes      from '@components/PropTypes';
import Api            from '@constants/Api';
import Workplace      from '@constants/Workplace';
import DateTimeHelper from '@helper/DateTime';
import Translator     from '@helper/Translator';
import Button         from '@stateless/atomic/Button';
import ButtonColor    from '@stateless/atomic/Button/ButtonColor';
import ButtonType     from '@stateless/atomic/Button/ButtonType';
import ContentWrapper from '@stateless/atomic/ContentWrapper';
import Icon           from '@stateless/atomic/Icon';
import IconType       from '@stateless/atomic/Icon/IconType';
import DatePicker     from '@stateless/composed/DatePicker';

import styles from './styles.module.scss';

const propTypes = {
    deliveryEnabled:       PropTypes.bool,
    numberOfPendingPizzas: PropTypes.number,
    onDateChanged:         PropTypes.func,
    onMenuOpenClicked:     PropTypes.func,
    selectedDate:          PropTypes.string,
    soldOutTill:           PropTypes.string,
    workplace:             PropTypes.string,
};

const Header = ({
    numberOfPendingPizzas = 0,
    selectedDate          = new Date(),
    onDateChanged         = _.noop,
    onMenuOpenClicked     = _.noop,
    workplace             = '',
    deliveryEnabled       = false,
    soldOutTill           = '',
}) => {
    const translator       = new Translator('components.header.');
    const workplaceIcon    = workplace === Workplace.kitchen ?
        IconType.pizza :
        IconType.cash;
    const currentlySoldOut = DateTimeHelper.isOnOrBeforeDay(soldOutTill, new Date());

    function renderDeliveryStatusText() {
        const deliveryClassName = deliveryEnabled ?
            styles.enabled :
            styles.disabled;
        const deliveryText      = deliveryEnabled ?
            translator.translate('deliveryEnabled') :
            translator.translate('deliveryDisabled');

        return (
            <div className={styles.menuLabelItem}>
                <Icon iconType={IconType.delivery} />
                <span>
                    {I18n.t('delivery')}
                </span>
                <span
                    className={deliveryClassName}
                >
                    {deliveryText}
                </span>
            </div>
        );
    }

    function renderSoldOutStatusText() {
        if (!currentlySoldOut) {
            return null;
        }

        return (
            <div className={styles.menuLabelItem}>
                <Icon iconType={IconType.pizza} />
                <span className={styles.disabled}>
                    {translator.translate('soldOutToday')}
                </span>
            </div>
        );
    }

    function renderToOrderAppButton() {
        if (workplace === Workplace.kitchen) {
            return null;
        }

        const orderUrl = Api.getOrderUrl(true);

        return (
            <a href={orderUrl}>
                <Button
                    type={ButtonType.pill}
                    text={translator.translate('toOrderApp')}
                    color={ButtonColor.black}
                    iconLeft={IconType.checkout}
                />
            </a>
        );
    }

    function renderPendingPizzasLabel() {
        if (workplace === Workplace.checkout) {
            return null;
        }

        return (
            <div className={styles.menuLabelItem}>
                <Icon iconType={IconType.pizza} />
                <span>
                    {translator.translate('pendingPizzasText', {
                        count: numberOfPendingPizzas,
                    })}
                </span>
            </div>
        );
    }

    return (
        <div className={styles.header}>
            <ContentWrapper>
                <div className={styles.headerContent}>
                    <div className={styles.menuLabelWrapper}>
                        <Button
                            type={ButtonType.pill}
                            text={I18n.t(workplace)}
                            color={ButtonColor.black}
                            iconLeft={workplaceIcon}
                        />
                        {renderDeliveryStatusText()}
                        {renderSoldOutStatusText()}
                    </div>
                    <div>
                        <DatePicker
                            value={selectedDate}
                            onChange={onDateChanged}
                        />
                    </div>
                    <div className={styles.menuLabelWrapper}>
                        {renderToOrderAppButton()}
                        {renderPendingPizzasLabel()}
                        <Button
                            type={ButtonType.text}
                            color={ButtonColor.black}
                            iconLeft={IconType.menu}
                            onClick={onMenuOpenClicked}
                        />
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

Header.propTypes = propTypes;

export default Header;
