//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

const initialState = Object.freeze({
    timeSlots: [],
});

const timeSlotsSlice = createSlice({
    name:     'timeSlots',
    initialState,
    reducers: {
        fetchTimeSlots:          _.noop,
        fetchTimeSlotsFailed:    _.noop,
        fetchTimeSlotsSucceeded: (state, action) => {
            return update(state, {
                timeSlots: {
                    $set: action.payload.timeSlots,
                },
            });
        },
    },
});

export const TimeSlotsActions = timeSlotsSlice.actions;

export const TimeSlotsReducer = timeSlotsSlice.reducer;

export default timeSlotsSlice;
