//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    dashed: PropTypes.bool,
    height: PropTypes.number,
    line:   PropTypes.bool,
};

const Spacer = ({
    height = 0,
    dashed = false,
    line   = false,
}) => {
    const halfHeight = height / 2;

    function renderSpacer() {
        if (!height) {
            return null;
        }

        const style = {
            height: halfHeight,
        };

        return (
            <div
                className={styles.spacer}
                style={style}
            />
        );
    }

    return (
        <div className={styles.spacerWrapper}>
            {renderSpacer()}
            <div
                className={classNames(
                    styles.line,
                    {
                        [styles.dashed]:  dashed,
                        [styles.visible]: line,
                    },
                )}
            />
            {renderSpacer()}
        </div>
    );
};

Spacer.propTypes = propTypes;

export default Spacer;
