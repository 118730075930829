//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';
import moment     from 'moment';

import PropTypes              from '@components/PropTypes';
import { VisibleOrderStatus } from '@constants/OrderStatus';
import PaymentStatus          from '@constants/PaymentStatus';
import PaymentType            from '@constants/PaymentType';
import ServingType            from '@constants/ServingType';
import Workplace              from '@constants/Workplace';
import DateTime               from '@helper/DateTime';
import OrderDisplayManager    from '@helper/OrderDisplayManager';
import Translator             from '@helper/Translator';
import UrlHelper              from '@helper/Url';
import Button                 from '@stateless/atomic/Button';
import ButtonColor            from '@stateless/atomic/Button/ButtonColor';
import ButtonType             from '@stateless/atomic/Button/ButtonType';
import CircleIcon             from '@stateless/atomic/CircleIcon';
import Icon                   from '@stateless/atomic/Icon';
import IconType               from '@stateless/atomic/Icon/IconType';
import OrderProductListItem   from '@stateless/atomic/OrderProductListItem';
import Price                  from '@stateless/atomic/Price';
import PriceSize              from '@stateless/atomic/Price/PriceSize';
import StatusButton           from '@stateless/atomic/StatusButton';

import styles from './styles.module.scss';

const propTypes = {
    extended:                 PropTypes.bool,
    isToday:                  PropTypes.bool,
    onOrderClicked:           PropTypes.func,
    order:                    PropTypes.object,
    updateOrder:              PropTypes.func,
    updateOrderProductStatus: PropTypes.func,
    workplace:                PropTypes.string,
};

const OrderListItem = ({
    order                    = null,
    isToday                  = false,
    updateOrder              = _.noop,
    updateOrderProductStatus = _.noop,
    workplace                = '',
    extended                 = false,
    onOrderClicked           = _.noop,
}) => {
    const startDateTime       = order.timeSlot?.startDateTime;
    const endDateTime         = order.timeSlot?.endDateTime;
    const translator          = new Translator('components.orderListItem.');
    const editUrl             = UrlHelper.createApplicationUrl(`/admin/app/order/list?filter[sequentialNumber][value]=${order.sequentialNumber}`);
    const sortedOrderProducts = OrderDisplayManager.sortOrderProducts(order.orderProducts);

    function renderItem(properties, defaultValue = '-', renderFunction = null) {
        const value = _.find(properties, (property) => {
            return !property;
        });

        if (value) {
            return (
                <div>
                    {defaultValue}
                </div>
            );
        }

        const propertyString = _.join(properties, ' ');

        return (
            <div>
                {propertyString}
            </div>
        );
    }

    function renderTimeSlotString() {
        let text = '-';

        if (
            startDateTime &&
            endDateTime
        ) {
            const startString = moment(startDateTime).format('HH:mm');
            const endString   = moment(endDateTime).format('HH:mm');
            text              = `${startString} - ${endString}`;
        }

        return (
            <div className={styles.timeSlotString}>
                {text}
            </div>
        );
    }

    function renderServingType() {
        const servingType        = order.servingType;
        const iconType           = servingType === ServingType.delivery ?
            IconType.delivery :
            IconType.checkout;
        const deliveryTimeString = servingType === ServingType.delivery ?
            DateTime.getHoursAndMinutes(order.calculatedDeliveryTime, I18n.t('oclock')) :
            null;

        function renderAddress() {
            if (servingType !== ServingType.delivery) {
                return null;
            }

            return (
                <>
                    <div>
                        {order.address?.street} {order.address?.houseNumber}
                    </div>
                    <div>
                        {order.address?.postalCode} {order.address?.city}
                    </div>
                </>
            );
        }

        return (
            <div className={styles.servingType}>
                <Icon iconType={iconType} />
                <div>
                    <div>
                        {I18n.t(servingType.toLowerCase())} {deliveryTimeString}
                    </div>
                    {renderAddress()}
                </div>
            </div>
        );
    }

    function renderAdditionalRequests() {
        if (
            !order.additionalRequests ||
            order.additionalRequests.length === 0
        ) {
            return null;
        }

        return _.map(order.additionalRequests, (additionalRequest) => {
            const key = `${order.iri}-${additionalRequest.iri}`;

            return (
                <div key={key}>
                    {`${additionalRequest.description}`}
                </div>
            );
        });
    }

    function renderCustomerComment() {
        if (!order.customerComment) {
            return null;
        }

        return (
            <div>
                {order.customerComment}
            </div>
        );
    }

    function onCustomerArrivedClicked(event) {
        event.stopPropagation();
        updateOrder(
            order.iri,
            {
                customerInStore: !order.customerInStore,
            },
        );
    }

    function renderStatusBar() {
        return _.map(Object.keys(VisibleOrderStatus), (key) => {
            const listItemKey       = `${order.iri}-${key}`;
            const statusButtonColor = OrderDisplayManager.getOrderStatusButtonColor(key);

            function onStatusButtonClicked(event) {
                event.stopPropagation();
                updateOrder(
                    order.iri,
                    {
                        status: key,
                    },
                );
            }

            return (
                <StatusButton
                    disabled={!isToday}
                    key={listItemKey}
                    onClick={onStatusButtonClicked}
                    active={order.status === key}
                    text={translator.translate(key)}
                    color={statusButtonColor}
                />
            );
        });
    }

    function renderOrderProducts() {
        return _.map(sortedOrderProducts, (orderProduct) => {
            return (
                <OrderProductListItem
                    key={orderProduct.iri}
                    isToday={isToday}
                    order={order}
                    updateOrderProductStatus={updateOrderProductStatus}
                    orderProduct={orderProduct}
                    workplace={workplace}
                />
            );
        });
    }

    function renderCustomerInStore() {
        if (order.servingType === ServingType.delivery) {
            return null;
        }

        if (workplace === Workplace.checkout) {
            const text        = order.customerInStore ?
                I18n.t('customerLeft') :
                I18n.t('customerArrived');
            const buttonColor = order.customerInStore ?
                ButtonColor.gray :
                ButtonColor.black;

            return (
                <div className={styles.customerArrivedButtonWrapper}>
                    <Button
                        type={ButtonType.pill}
                        text={text}
                        color={buttonColor}
                        onClick={onCustomerArrivedClicked}
                        disabled={!isToday}
                    />
                </div>
            );
        }

        if (
            workplace === Workplace.kitchen &&
            order.customerInStore
        ) {
            return (
                <p className={styles.customerArrivedLabel}>
                    {I18n.t('customerInStore')}
                </p>
            );
        }

        return null;
    }

    function renderPaidLabel() {
        if (
            workplace === Workplace.checkout &&
            order.paymentType === PaymentType.ONLINE &&
            order.paymentStatus === PaymentStatus.SUCCEEDED
        ) {
            return (
                <p className={styles.paidLabel}>
                    {I18n.t('paid')}
                </p>
            );
        }

        return null;
    }

    function renderPrice() {
        if (workplace === Workplace.checkout) {
            return (
                <Price
                    value={order.totalPrice}
                    size={PriceSize.small}
                />
            );
        }

        return null;
    }

    function getEditButton() {
        if (workplace === Workplace.checkout) {
            return (
                <a
                    href={editUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <CircleIcon
                        iconType={IconType.edit}
                    />
                </a>
            );
        }

        return null;
    }

    function renderOrderLabel() {
        return (
            <div className={styles.orderLabel}>
                {I18n.t('orderNumber', {
                    orderNumber: order.sequentialNumber,
                })}
                {getEditButton()}
            </div>
        );
    }

    return (
        <div className={styles.orderListItemWrapper}>
            <div
                className={styles.orderListItemHeader}
                onClick={onOrderClicked}
            >
                <div className={styles.orderDetail}>
                    <div>
                        {renderOrderLabel()}
                        {renderTimeSlotString()}
                        {renderItem([
                            order.firstname,
                            order.lastname,
                        ])}
                        {renderItem([order.email])}
                        {renderItem([order.phoneNumber])}
                    </div>
                    <div>
                        {renderServingType()}
                        {renderCustomerInStore()}
                        {renderAdditionalRequests()}
                        {renderCustomerComment()}
                    </div>
                </div>
                <div className={styles.orderPrice}>
                    {renderPrice()}
                    {renderPaidLabel()}
                </div>
                <div className={styles.orderStatusList}>
                    {renderStatusBar()}
                </div>
            </div>
            <div
                className={classNames(
                    styles.orderListItemDetail,
                    {
                        [styles.extended]: extended,
                    },
                )}
            >
                {renderOrderProducts()}
            </div>
        </div>
    );
};

OrderListItem.propTypes = propTypes;

export default OrderListItem;
