//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

export default (api) => {
    return {
        get:     (iri) => () => {
            return api.get(iri);
        },
        fetch:   () => {
            return api.get(ApiUrls.API_TIME_SLOTS);
        },
        reserve: (iri, sessionId) => () => {
            const reserveUrl = ApiUrls.API_TIME_SLOTS_RESERVE(iri);

            return api.put(reserveUrl, {}, {
                params: {
                    sessionKey: sessionId,
                },
            });
        },
        release: (iri, sessionId) => () => {
            const releaseUrl = ApiUrls.API_TIME_SLOTS_RELEASE(iri);

            return api.put(releaseUrl, {}, {
                params: {
                    sessionKey: sessionId,
                },
            });
        },
    };
};
