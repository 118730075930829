//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useLoading }          from '@slices/loading';
import StatelessLoadingOverlay from '@stateless/atomic/LoadingOverlay';
import selectLoading           from '@store/selectors/loading';
import { selectLoadingText }   from '@store/selectors/loading';

const LoadingOverlay = () => {
    const dispatch       = useDispatch();
    const isLoading      = useSelector(selectLoading);
    const text           = useSelector(selectLoadingText);
    const loadingActions = useLoading(dispatch);

    function loadingOverlayClicked() {
        loadingActions.overlayClicked();
    }

    return (
        <StatelessLoadingOverlay
            isLoading={isLoading}
            loadingOverlayClicked={loadingOverlayClicked}
            text={text}
        />
    );
};

export default LoadingOverlay;
