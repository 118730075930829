//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import * as Api            from '@api/index';
import Hydra               from '@helper/Hydra';
import { ProductsActions } from '@slices/products';

function* fetchProducts(action) {
    const response = yield call(Api.context.products.fetch);

    if (response.ok) {
        const products = Hydra.getMembersFromResponse(response.data);

        yield put(ProductsActions.fetchProductsSucceeded({
            products,
        }));
    } else {
        yield put(ProductsActions.fetchProductsFailed());
    }
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProductsActions.fetchProducts().type], fetchProducts),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
