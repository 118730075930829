//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    PENDING:                 'PENDING',
    PROCESSING:              'PROCESSING',
    REQUIRES_CAPTURE:        'REQUIRES_CAPTURE',
    SUCCEEDED:               'SUCCEEDED',
    PAYMENT_FAILED:          'PAYMENT_FAILED',
    REQUIRES_PAYMENT_METHOD: 'REQUIRES_PAYMENT_METHOD',
    REQUIRES_ACTION:         'REQUIRES_ACTION',
    REQUIRES_CONFIRMATION:   'REQUIRES_CONFIRMATION',
});
