//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import OrderStatus from '@constants/OrderStatus';

class OrderProvider {
    static filterOrders = (orders, workplace, showServedOrders) => {
        return orders.filter((order) => {
            if (order.status === OrderStatus.PENDING_PAYMENT) {
                return false;
            }

            if (
                order.status === OrderStatus.SERVED &&
                !showServedOrders
            ) {
                return false;
            }

            return true;
        });
    };

    static sortOrders = (orders) => {
        function getDateTime(order) {
            return order?.timeSlot?.startDateTime;
        }

        orders.sort((leftOrder, rightOrder) => {
            const leftOrderDateTime  = getDateTime(leftOrder);
            const rightOrderDateTime = getDateTime(rightOrder);

            if (leftOrderDateTime < rightOrderDateTime) {
                return -1;
            }

            if (leftOrderDateTime > rightOrderDateTime) {
                return 1;
            }

            return 0;
        });

        return orders;
    };

    static getFilteredAndSortedOrders = (orders, workplace, showServedOrders) => {
        const filteredOrders = OrderProvider.filterOrders(orders, workplace, showServedOrders);

        return OrderProvider.sortOrders(filteredOrders);
    };
}

export default OrderProvider;
