//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
};

const ContentWrapper = ({
    children  = null,
    className = '',
}) => {
    return (
        <div
            className={classNames(
                styles.contentWrapper,
                className,
            )}
        >
            {children}
        </div>
    );
};

ContentWrapper.propTypes = propTypes;

export default ContentWrapper;
