//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n   from 'i18next';
import moment from 'moment';

class DateTime {
    static getCurrentTime = () => {
        const now         = new Date();
        const currentTime = now.getTime();

        return currentTime;
    };

    static getCurrentTimeInSeconds = () => {
        return DateTime.getCurrentTime() / 1000;
    };

    static getDaysInMilliseconds = (days) => {
        return DateTime.getHoursInMilliseconds(days * 24);
    };

    static getHoursInMilliseconds = (hours) => {
        return DateTime.getMinutesInMilliseconds(hours * 60);
    };

    static getMinutesInMilliseconds = (minutes) => {
        return DateTime.getSecondsInMilliseconds(minutes * 60);
    };

    static getSecondsInMilliseconds = (seconds) => {
        return seconds * 1000;
    };

    static getTimeString = (dateTime, fallbackText) => {
        const messageDate = moment(dateTime);

        if (messageDate.isValid()) {
            const today           = moment();
            const timeStringParts = [];

            if (messageDate.isSame(today, 'day')) {
                timeStringParts.push(I18n.t('today'));
            } else {
                const yesterday = moment().subtract(1, 'day');

                if (messageDate.isSame(yesterday, 'day')) {
                    timeStringParts.push(I18n.t('yesterday'));
                } else {
                    timeStringParts.push(messageDate.format(I18n.t('messageDateFormat')));
                }
            }

            timeStringParts.push(messageDate.format(I18n.t('messageTimeFormat')));
            timeStringParts.push(I18n.t('oclock'));

            const timeString = timeStringParts.join(' ');

            return timeString;
        }

        return fallbackText;
    };

    static getHoursAndMinutes = (dateTime, suffix) => {
        const messageDate = moment(dateTime);
        let timeString    = '';

        if (messageDate.isValid()) {
            timeString = messageDate.format(I18n.t('messageTimeFormat'));
        }

        if (suffix) {
            timeString += ` ${suffix}`;
        }

        return timeString;
    };

    static isOnOrBeforeDay = (deadline, dateTime) => {
        const messageDate      = moment(dateTime);
        const endOfDeadlineDay = moment(deadline).endOf('day');

        if (messageDate.isValid()) {
            return messageDate.isSameOrBefore(endOfDeadlineDay, 'day');
        }

        return false;
    };
}

export default DateTime;
