//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

const initialState = Object.freeze({
    currentOverlay:   null,
    previousOverlays: [],
});

const overlaySlice = createSlice({
    name:     'overlay',
    initialState,
    reducers: {
        resetOverlays: () => {
            return initialState;
        },
        openOverlay:   (state, action) => {
            const { payload }      = action;
            const { overlay }      = payload;
            const clearHistory     = _.get(payload, 'clearHistory', false);
            const previousOverlays = (
                clearHistory ?
                    {
                        $set: [],
                    } :
                    {
                        $push: [state.currentOverlay],
                    }
            );

            return update(state, {
                currentOverlay: {
                    $set: overlay,
                },
                previousOverlays,
            });
        },
        closeOverlay:  (state, action) => {
            const { payload } = action;
            const force       = _.get(payload, 'force', false);
            const count       = _.get(payload, 'count', 1);

            if (force) {
                return initialState;
            }

            return update(state, {
                currentOverlay:   {
                    $set: state.previousOverlays[state.previousOverlays.length - count],
                },
                previousOverlays: {
                    $splice: [[state.previousOverlays.length - count, count]],
                },
            });
        },
    },
});

export const OverlayActions = overlaySlice.actions;

export const OverlayReducer = overlaySlice.reducer;

export default overlaySlice;
