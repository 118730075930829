//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Helmet } from 'react-helmet';

import DefaultTypes    from '@components/DefaultTypes';
import PropTypes       from '@components/PropTypes';
import PageTitleHelper from '@helper/PageTitle';

/**
 * If this component is missing features, you have to use <Helmet> on your own since
 * this is only shortcut for:
 *
 * <Helmet>
 *     <title>
 *         {PageTitleHelper.getPageTitle(I18n.t('example'))}
 *     </title>
 * </Helmet>
 */

const propTypes = {
    title: PropTypes.string,
};

const PageTitle = ({
    title = DefaultTypes.func,
}) => {
    return (
        <Helmet>
            <title>
                {PageTitleHelper.getPageTitle(title)}
            </title>
        </Helmet>
    );
};

PageTitle.propTypes = propTypes;

export default PageTitle;
