//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

class PageTitleHelper {
    static getPageTitle(prefix) {
        const pageTitle = `${prefix} - ${I18n.t('pageTitle')}`;

        return pageTitle;
    }
}

export default PageTitleHelper;
