//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes  from '@components/PropTypes';
import CircleSize from '@stateless/atomic/CircleIcon/CircleSize';
import Icon       from '@stateless/atomic/Icon';

import CircleColor from './CircleColor';
import styles      from './styles.module.scss';

const propTypes = {
    circleColor: PropTypes.oneOfObjectKeys(CircleColor),
    circleSize:  PropTypes.oneOfObjectKeys(CircleSize),
    iconType:    PropTypes.iconType,
    onClick:     PropTypes.func,
};

const CircleIcon = ({
    circleColor = CircleColor.gray,
    iconType    = null,
    onClick     = null,
    circleSize  = CircleSize.default,
}) => {
    const [pressed, setPressed] = React.useState(false);

    function onMouseDown(event) {
        setPressed(true);
    }

    function onMouseUp(event) {
        setPressed(false);
    }

    return (
        <div
            className={classNames(
                styles.circleIcon,
                {
                    [styles.circleGray]:        circleColor === CircleColor.gray,
                    [styles.circleOrange]:      circleColor === CircleColor.orange,
                    [styles.circleBlack]:       circleColor === CircleColor.black,
                    [styles.circleTransparent]: circleColor === CircleColor.transparent,
                    [styles.big]:               circleSize === CircleSize.big,
                    [styles.pressed]:           pressed,
                },
            )}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            <Icon
                iconType={iconType}
                circleColor={circleColor}
            />
        </div>
    );
};

CircleIcon.propTypes = propTypes;

export default CircleIcon;
