//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';
import { Route }       from 'react-router';
import { Switch }      from 'react-router';
import { Redirect }    from 'react-router';

import OverlayManger from '@connected/OverlayManager';
import Routes        from '@constants/Routes';
import Home          from '@screens/Home';
import Login         from '@screens/Login';
import NotFound      from '@screens/NotFound';
import selectToken   from '@store/selectors/user';

const propTypes = {};

const MainRouter = () => {
    const token = useSelector(selectToken);

    function renderLogin() {
        if (token) {
            return (
                <Redirect to={Routes.home} />
            );
        }

        return (
            <Login />
        );
    }

    return (
        <>
            <OverlayManger />
            <Switch>
                <Route
                    exact
                    path={Routes.login}
                    component={Login}
                >
                    {renderLogin()}
                </Route>
                <Route
                    exact
                    path={Routes.home}
                    component={Home}
                />
                <Route
                    exact
                    path={Routes.home}
                    component={Home}
                />
                <Route component={NotFound} />
            </Switch>
        </>
    );
};

MainRouter.propTypes = propTypes;

export default MainRouter;
