//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import IconAllergy      from '@assets/icons/allergy.svg';
import IconArrowLeft    from '@assets/icons/arrowLeft.svg';
import IconCalendar     from '@assets/icons/calendar.svg';
import IconCash         from '@assets/icons/cash.svg';
import IconChecked      from '@assets/icons/checked.svg';
import IconCheckout     from '@assets/icons/checkout.svg';
import IconChevronRight from '@assets/icons/chevronRight.svg';
import IconCross        from '@assets/icons/cross.svg';
import IconDelivery     from '@assets/icons/delivery.svg';
import IconDrink        from '@assets/icons/drink.svg';
import IconEdit         from '@assets/icons/edit.svg';
import IconFlame        from '@assets/icons/flame.svg';
import IconForms        from '@assets/icons/forms.svg';
import IconInfo         from '@assets/icons/info.svg';
import IconInstagram    from '@assets/icons/instagram.svg';
import IconMenu         from '@assets/icons/menu.svg';
import IconMinus        from '@assets/icons/minus.svg';
import IconNote         from '@assets/icons/note.svg';
import IconPizza        from '@assets/icons/pizza.svg';
import IconPlus         from '@assets/icons/plus.svg';
import IconUnchecked    from '@assets/icons/unchecked.svg';
import IconVegan        from '@assets/icons/vegan.svg';
import PropTypes        from '@components/PropTypes';

import IconType from './IconType';
import styles   from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
    onClick:  PropTypes.func,
};

const Icon = ({
    iconType = null,
    onClick  = null,
}) => {
    function renderSvg() {
        const iconMapping = {
            [IconType.allergy]:      <IconAllergy />,
            [IconType.arrowLeft]:    <IconArrowLeft />,
            [IconType.calendar]:     <IconCalendar />,
            [IconType.checked]:      <IconChecked />,
            [IconType.checkout]:     <IconCheckout />,
            [IconType.chevronRight]: <IconChevronRight />,
            [IconType.cross]:        <IconCross />,
            [IconType.delivery]:     <IconDelivery />,
            [IconType.flame]:        <IconFlame />,
            [IconType.cash]:         <IconCash />,
            [IconType.drink]:        <IconDrink />,
            [IconType.forms]:        <IconForms />,
            [IconType.info]:         <IconInfo />,
            [IconType.vegan]:        <IconVegan />,
            [IconType.edit]:         <IconEdit />,
            [IconType.instagram]:    <IconInstagram />,
            [IconType.menu]:         <IconMenu />,
            [IconType.note]:         <IconNote />,
            [IconType.pizza]:        <IconPizza />,
            [IconType.unchecked]:    <IconUnchecked />,
            [IconType.plus]:         <IconPlus />,
            [IconType.minus]:        <IconMinus />,
        };

        return iconMapping[iconType];
    }

    return (
        <span
            className={styles.icon}
            onClick={onClick}
        >
            {renderSvg()}
        </span>
    );
};

Icon.propTypes = propTypes;

export default Icon;
