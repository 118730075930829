//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    additionalRequests: [],
});

const additionalRequestsSlice = createSlice({
    name:     'additionalRequests',
    initialState,
    reducers: {
        fetchAdditionalRequests:          LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchAdditionalRequestsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchAdditionalRequestsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                additionalRequests: {
                    $set: action.payload.additionalRequests,
                },
            });
        }),
    },
});

export const AdditionalRequestsActions = additionalRequestsSlice.actions;

export const AdditionalRequestsReducer = additionalRequestsSlice.reducer;

export default additionalRequestsSlice;
