//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import preval from 'preval.macro';

import EnvironmentTypes from '@constants/Enironments';

import packageJson from '../../package.json';

class Environment {
    static buildTimestamp = preval`module.exports = new Date().getTime();`;

    static getEnvironment = () => {
        return process.env.NODE_ENV;
    };

    static isEnvironmentByNodeEnv = (environment) => {
        return Environment.getEnvironment() === environment;
    };

    static isDevelopment = () => {
        return Environment.isEnvironmentByNodeEnv(EnvironmentTypes.DEVELOPMENT);
    };

    static isProduction = () => {
        return (
            Environment.isEnvironmentByNodeEnv(EnvironmentTypes.PRODUCTION) &&
            !Environment.isStaging()        // Environment is staging depending on the URL
        );
    };

    static isStaging = () => {
        return Environment.isStagingByUrl();
    };

    static isStagingByUrl = () => {
        const { href } = window.location;

        return (
            href.indexOf('.test') > -1 ||
            href.indexOf('staging') > -1 ||
            href.indexOf('localhost') > -1
        );
    };

    static isTest = () => {
        const environment = Environment.getEnvironment();

        return (
            environment.indexOf('jsdom') > -1 ||
            Environment.isEnvironmentByNodeEnv(EnvironmentTypes.TEST)
        );
    };

    static getVersion = () => {
        return `${packageJson.version}.${Environment.buildTimestamp}`;
    };
}

export default Environment;
