//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';

import DefaultTypes       from '@components/DefaultTypes';
import PropTypes          from '@components/PropTypes';
import ButtonColor        from '@stateless/atomic/Button/ButtonColor';
import ButtonIconPosition from '@stateless/atomic/Button/ButtonIconPosition';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import Icon               from '@stateless/atomic/Icon';
import Price              from '@stateless/atomic/Price';
import PriceColor         from '@stateless/atomic/Price/PriceColor';
import PriceSize          from '@stateless/atomic/Price/PriceSize';

import styles from './styles.module.scss';

const propTypes = {
    color:             PropTypes.buttonColor,
    disabled:          PropTypes.bool,
    disabledHoverText: PropTypes.string,
    iconLeft:          PropTypes.iconType,
    iconRight:         PropTypes.iconType,
    onClick:           PropTypes.func,
    subIconLeft:       PropTypes.iconType,
    subText:           PropTypes.string,
    text:              PropTypes.string,
    textColor:         PropTypes.buttonColor,
    type:              PropTypes.buttonType,
    value:             PropTypes.number,
};

const Button = ({
    color             = ButtonColor.default,
    disabled          = false,
    iconLeft          = null,
    iconRight         = null,
    onClick           = DefaultTypes.noop,
    text              = '',
    textColor         = ButtonColor.black,
    value             = 0,
    type              = ButtonType.default,
    subText           = null,
    subIconLeft       = null,
    disabledHoverText = null,
}) => {
    const [pressed, setPressed] = React.useState(false);

    function renderButtonContent() {
        if (type === ButtonType.checkout) {
            return (
                <Price
                    value={value}
                    color={PriceColor.white}
                    size={PriceSize.small}
                    emptyText={I18n.t('checkout')}
                />
            );
        }

        return (
            <p>
                {text}
            </p>
        );
    }

    function renderIcon(iconType, iconPosition) {
        if (iconType) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        {
                            [styles.iconContainerLeft]:    iconPosition === ButtonIconPosition.left,
                            [styles.iconContainerRight]:   iconPosition === ButtonIconPosition.right,
                            [styles.iconContainerSubLeft]: iconPosition === ButtonIconPosition.subLeft,
                        },
                    )}
                >
                    <Icon iconType={iconType} />
                </div>
            );
        }

        return null;
    }

    function renderDisabledHoverPopup() {
        if (
            !disabledHoverText ||
            !disabled
        ) {
            return null;
        }

        return (
            <div className={styles.disabledHoverPopup}>
                {disabledHoverText}
            </div>
        );
    }

    function onMouseDown(event) {
        setPressed(true);
    }

    function onMouseUp(event) {
        setPressed(false);
    }

    return (
        <div className={styles.buttonWrapper}>

            <button
                className={classNames(
                    styles.button,
                    {
                        [styles.pressed]:    pressed,
                        [styles.iconOnly]:   !text,
                        [styles.black]:      color === ButtonColor.black,
                        [styles.gray]:       color === ButtonColor.gray,
                        [styles.pill]:       type === ButtonType.pill,
                        [styles.checkout]:   type === ButtonType.checkout,
                        [styles.text]:       type === ButtonType.text,
                        [styles.textOrange]: textColor === ButtonColor.orange,
                    },
                )}
                onClick={onClick}
                disabled={disabled}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                <div className={styles.textRow}>
                    {renderIcon(iconLeft, ButtonIconPosition.left)}
                    {renderButtonContent()}
                    {renderIcon(iconRight, ButtonIconPosition.right)}
                </div>
                <div className={styles.subTextRow}>
                    {renderIcon(subIconLeft, ButtonIconPosition.subLeft)}
                    <p>
                        {subText}
                    </p>
                </div>
            </button>
            {renderDisabledHoverPopup()}
        </div>
    );
};

Button.propTypes = propTypes;

export default Button;
