//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import _               from 'lodash';

const initialState = Object.freeze({});

const orderProductsSlice = createSlice({
    name:     'orderProducts',
    initialState,
    reducers: {
        updateOrderProductStatus: _.noop,
    },
});

export const OrderProductsActions = orderProductsSlice.actions;

export const OrderProductsReducer = orderProductsSlice.reducer;

export default orderProductsSlice;
