//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Sentry                   from '@sentry/react';
import { routerMiddleware }          from 'connected-react-router';
import { createBrowserHistory }      from 'history';
import _                             from 'lodash';
import { applyMiddleware }           from 'redux';
import { compose }                   from 'redux';
import { createStore }               from 'redux';
import { persistReducer }            from 'redux-persist';
import { persistStore }              from 'redux-persist';
import storage                       from 'redux-persist/lib/storage';
import withReady                     from 'redux-ready';
import createSagaMiddleware          from 'redux-saga';
import sentryMiddleware              from 'redux-sentry-middleware';
import { initMessageListener }       from 'redux-state-sync';
import { createStateSyncMiddleware } from 'redux-state-sync';

import Environment     from '@helper/Environment';
import { UserActions } from '@slices/user';

import actionBlockerMiddleware from './miscellaneous/actionBlocker';
import loadingMiddleware       from './miscellaneous/loading';
import rootReducer             from './reducer';
import sagas                   from './sagas/index';
import apiMiddleware           from './utils/api';

const apiMiddlewareService           = apiMiddleware();
const history                        = createBrowserHistory();
const sagaMiddleware                 = createSagaMiddleware({
    onError(error) {
        console.error(error);
        Sentry.captureException(error);
    },
});
const loadingMiddlewareService       = loadingMiddleware();
const sentryMiddlewareService        = sentryMiddleware(Sentry);
const actionBlockerMiddlewareService = actionBlockerMiddleware();
const persistConfig                  = {
    key:       'root',
    storage,
    blacklist: [
        'loading',
        'router',
    ],
};
const persistedReducer               = persistReducer(
    persistConfig,
    rootReducer(history),
);
const stateSyncConfig                = {
    whitelist: [
        UserActions.logout().type,
    ],
};

export {
    history,
    sagaMiddleware,
};

export default (initialState) => {
    const isDevelopment                 = Environment.isDevelopment();
    const allMiddlewares                = [
        (
            isDevelopment ?
                // This is inline since we only want to load this module in development mode
                // eslint-disable-next-line global-require
                require('redux-immutable-state-invariant').default() :
                null
        ),
        sentryMiddlewareService,
        actionBlockerMiddlewareService,
        loadingMiddlewareService,
        apiMiddlewareService,
        sagaMiddleware,
        routerMiddleware(history),
        createStateSyncMiddleware(stateSyncConfig),
    ];
    const middlewares                   = _.reject(allMiddlewares, _.isNull);
    // eslint-disable-next-line no-underscore-dangle
    const reduxDevToolsExtensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    const composeEnhancers              = (
        (
            typeof window === 'object' &&
            reduxDevToolsExtensionCompose
        ) ?
            reduxDevToolsExtensionCompose({
                trace:      true,
                traceLimit: 25,
            }) :
            compose
    ); // add support for Redux dev tools
    const store                         = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            withReady,
            applyMiddleware(...middlewares),
        ),
    );

    store.ready().then(() => {
        sagaMiddleware.run(sagas.root);
    });

    initMessageListener(store);
    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
};
