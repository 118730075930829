//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import * as Api                      from '@api';
import Hydra                         from '@helper/Hydra';
import { AdditionalRequestsActions } from '@slices/additionalRequests';

function* fetchAdditionalRequests(action) {
    const response = yield call(Api.context.additionalRequests.fetch);

    if (response.ok) {
        const additionalRequests = Hydra.getMembersFromResponse(response.data);

        yield put(AdditionalRequestsActions.fetchAdditionalRequestsSucceeded({
            additionalRequests,
        }));
    } else {
        yield put(AdditionalRequestsActions.fetchAdditionalRequestsFailed());
    }
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([AdditionalRequestsActions.fetchAdditionalRequests().type], fetchAdditionalRequests),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
