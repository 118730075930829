//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    restaurants:       [],
    currentRestaurant: null,
});

const restaurantsSlice = createSlice({
    name:     'restaurants',
    initialState,
    reducers: {
        fetchRestaurants:          LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchRestaurantsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchRestaurantsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                restaurants: {
                    $set: action.payload.restaurants,
                },
            });
        }),
        setCurrentRestaurant:      (state, action) => {
            return update(state, {
                currentRestaurant: {
                    $set: action.payload,
                },
            });
        },
    },
});

export const RestaurantsActions = restaurantsSlice.actions;

export const RestaurantsReducer = restaurantsSlice.reducer;

export default restaurantsSlice;
