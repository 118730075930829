//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import OrderProductStatus from '@constants/OrderProductStatus';
import OrderStatus        from '@constants/OrderStatus';
import ProductType        from '@constants/ProductType';
import StatusButtonColor  from '@stateless/atomic/StatusButton/StatusButtonColor';

class OrderDisplayManager {
    static sortOrderProducts(orderProducts) {
        const typeOrder = [
            ProductType.pizza,
            ProductType.drink,
        ];

        return _.sortBy(orderProducts, (orderProduct) => {
            const { name, type } = orderProduct.product;
            const typeIndex      = typeOrder.indexOf(type);

            return `${typeIndex}-${name}`;
        });
    }

    static getOrderStatusButtonColor(orderStatus) {
        switch (orderStatus) {
            case OrderStatus.IN_PROGRESS:
                return StatusButtonColor.orange;
            case OrderStatus.FINISHED:
                return StatusButtonColor.green;
            case OrderStatus.IN_DELIVERY:
                return StatusButtonColor.orange;
            case OrderStatus.SERVED:
                return StatusButtonColor.green;
        }

        return StatusButtonColor.black;
    }

    static getOrderPositionStatusButtonColor(orderPositionStatus) {
        switch (orderPositionStatus) {
            case OrderProductStatus.FINISHED:
                return StatusButtonColor.green;
            default:
                return StatusButtonColor.black;
        }
    }
}

export default OrderDisplayManager;
