//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({});

const browserSlice = createSlice({
    name:     'browser',
    initialState,
    reducers: {
        scrollToTop:              (state, action) => {
        },
        scrollToTopReactDataGrid: (state, action) => {
        },
    },
});

export const BrowserActions = browserSlice.actions;

export const BrowserReducer = browserSlice.reducer;

export const useBrowser = (dispatch) => bindActionCreators(BrowserActions, dispatch);

export default browserSlice;
