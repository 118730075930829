//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import Workplace          from '@constants/Workplace';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    lastLogin: null,
    password:  '',
    token:     null,
    username:  '',
    iri:       null,
    workplace: Workplace.kitchen,
});

const userSlice = createSlice({
    name:     'user',
    initialState,
    reducers: {
        login:                LoadingLevelHelper.increaseLoadingEmptyReducer(),
        loginWithCredentials: _.noop,
        loginFailed:          LoadingLevelHelper.decreaseLoading(() => {
        }),
        loginSucceeded:       LoadingLevelHelper.decreaseLoading((state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                lastLogin: {
                    $set: payload.lastLogin.date,
                },
                password:  {
                    $set: '',
                },
                token:     {
                    $set: payload.token,
                },
                username:  {
                    $set: payload.username,
                },
                iri:       {
                    $set: payload.userId,
                },
            });
        }),
        logout:               (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        setPassword:          (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                password: {
                    $set: payload.password,
                },
            });
        },
        setUsername:          (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                username: {
                    $set: payload.username,
                },
            });
        },
        tryRestoreToken:      (state, action) => {
        },
        fetchUser:            LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchUserFailed:      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchUserSucceeded:   LoadingLevelHelper.decreaseLoading((state, action) => {
            const {
                userProfile,
            } = action.payload;

            return update(state, {
                userProfile: {
                    $set: userProfile,
                },
            });
        }),
        setWorkplace:         (state, action) => {
            const workplace = _.get(action, 'payload.workplace', Workplace.kitchen);

            return update(state, {
                workplace: {
                    $set: workplace,
                },
            });
        },
    },
});

export const UserActions = userSlice.actions;

export const UserReducer = userSlice.reducer;
export const useUser     = (dispatch) => bindActionCreators(UserActions, dispatch);

export default userSlice;
