//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    PENDING:         'PENDING',
    IN_PROGRESS:     'IN_PROGRESS',
    FINISHED:        'FINISHED',
    PENDING_PAYMENT: 'PENDING_PAYMENT',
    IN_DELIVERY:     'IN_DELIVERY',
    SERVED:          'SERVED',
});

export const VisibleOrderStatus = Object.freeze({
    PENDING:     'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    FINISHED:    'FINISHED',
    IN_DELIVERY: 'IN_DELIVERY',
    SERVED:      'SERVED',
});
