//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    error_404:                  '404',
    pageNotFound:               'Seite nicht gefunden',
    messageTimeFormat:          'HH:mm',
    pageTitle:                  'clausgemacht',
    stagingPasswordPlaceholder: 'Passwort',
    loading:                    'Wird geladen...',
    delivery:                   'Lieferung',
    home:                       'Clausgemacht',
    collection:                 'Abholung',
    today:                      'Heute',
    oclock:                     'Uhr',
    logout:                     'Abmelden',
    edit:                       'Bearbeiten',
    paid:                       'Online bezahlt',
    orderNumber:                'Bestellung #{{orderNumber}}',
    pizza:                      'Pizza',
    drinks:                     'Getränke',
    customerArrived:            'Kunde angekommen',
    customerLeft:               'Kunde gegangen',
    customerInStore:            'Kunde im Laden',
    mondayShort:                'Mo',
    tuesdayShort:               'Di',
    wednesdayShort:             'Mi',
    thursdayShort:              'Do',
    fridayShort:                'Fr',
    saturdayShort:              'Sa',
    sundayShort:                'So',
    januaryShort:               'Jan',
    kitchen:                    'Küche',
    checkout:                   'Kasse',
    februaryShort:              'Feb',
    marchShort:                 'Mär',
    aprilShort:                 'Apr',
    mayShort:                   'Mai',
    juneShort:                  'Jun',
    julyShort:                  'Jul',
    augustShort:                'Aug',
    septemberShort:             'Sep',
    octoberShort:               'Okt',
    novemberShort:              'Nov',
    decemberShort:              'Dez',
    components:                 {
        login:                {
            emailLabel:    'E-Mail',
            passwordLabel: 'Passwort',
            login:         'Anmelden',
        },
        orderListItem:        {
            PENDING:     'Ausstehend',
            IN_PROGRESS: 'Bearbeitung',
            FINISHED:    'Fertig',
            IN_DELIVERY: 'Ausliefern',
            SERVED:      'Serviert',
        },
        orderProductListItem: {
            PENDING:     'Ausstehend',
            IN_OVEN:     'Ofen',
            IN_REFINING: 'Veredelung',
            FINISHED:    'Fertig',
        },
        menuOverlay:          {
            workingPlace:          'An welcher Station arbeitest du?',
            deliveryEnabled:       'Lieferung ist momentan aktiviert',
            deliveryDisabled:      'Lieferung ist momentan deaktiviert',
            enableDelivery:        'Lieferung aktivieren',
            disableDelivery:       'Lieferung deaktivieren',
            servedOrdersShown:     'Servierte Bestellungen werden angezeigt',
            servedOrdersHidden:    'Servierte Bestellungen werden nicht angezeigt',
            showServedOrders:      'Servierte Bestellungen anzeigen',
            hideServedOrders:      'Servierte Bestellungen verstecken',
            soldOutTodayHeader:    'Für heute kann keiner mehr bestellen',
            notSoldOutTodayHeader: 'Bestellungen sind heute noch möglich',
            setSoldOutToday:       'Heute ausverkauft',
            setNotSoldOutToday:    'Heute doch nicht ausverkauft',
        },
        header:               {
            deliveryEnabled:   'aktiv',
            deliveryDisabled:  'nicht aktiv',
            pendingPizzasText: '{{count}} in Auftrag',
            toOrderApp:        'Zur Bestell-App',
            soldOutToday:      'ausverkauft',
        },
    },
    notifications:              {
        loginError:                     'Leider ist ein Fehler beim Login aufgetreten. Bitte überprüfe deine Zugangsdaten und versuche es erneut.',
        orderStatusUpdateFailed:        'Leider ist ein Fehler beim Aktualisieren des Bestellstatus aufgetreten. Bitte versuche es erneut.',
        fetchOrderFailed:               'Leider ist ein Fehler beim Abrufen der Bestellung aufgetreten.',
        orderProductStatusUpdateFailed: 'Leider ist ein Fehler beim Aktualisieren des Positionsstatus aufgetreten. Bitte versuche es erneut.',
    },
});

export default german;
