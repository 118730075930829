//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import ReactDatePicker from 'react-date-picker';

import PropTypes   from '@components/PropTypes';
import Button      from '@stateless/atomic/Button';
import ButtonColor from '@stateless/atomic/Button/ButtonColor';
import ButtonType  from '@stateless/atomic/Button/ButtonType';
import Icon        from '@stateless/atomic/Icon';
import IconType    from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const propTypes = {
    onChange: PropTypes.func,
    value:    PropTypes.string,
};

const DatePicker = ({
    value    = '',
    onChange = _.noop,
}) => {
    const calendarIcon = (
        <Icon iconType={IconType.calendar} />
    );

    function onTodayClicked() {
        onChange(new Date());
    }

    return (
        <div className={styles.datePickerWrapper}>
            <ReactDatePicker
                className={styles.datePicker}
                value={value}
                onChange={onChange}
                calendarIcon={calendarIcon}
                clearIcon={null}
                format="dd.MM.yyyy"
                calendarClassName={styles.calendar}
                locale="de-DE"
            />
            <Button
                type={ButtonType.pill}
                color={ButtonColor.gray}
                text={I18n.t('today')}
                onClick={onTodayClicked}
            />
        </div>
    );
};

DatePicker.propTypes = propTypes;

export default DatePicker;
