//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    products: [],
});

const productsSlice = createSlice({
    name:     'products',
    initialState,
    reducers: {
        fetchProducts:          LoadingLevelHelper.increaseLoadingEmptyReducer(),
        fetchProductsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchProductsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                products: {
                    $set: _.get(action, 'payload.products'),
                },
            });
        }),
    },
});

export const ProductsActions = productsSlice.actions;

export const ProductsReducer = productsSlice.reducer;

export default productsSlice;
