//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes      from '@components/PropTypes';
import Workplace      from '@constants/Workplace';
import DateTimeHelper from '@helper/DateTime';
import Translator     from '@helper/Translator';
import Button         from '@stateless/atomic/Button';
import ButtonColor    from '@stateless/atomic/Button/ButtonColor';
import ButtonType     from '@stateless/atomic/Button/ButtonType';
import IconType       from '@stateless/atomic/Icon/IconType';
import MenuItem       from '@stateless/atomic/MenuItem';
import Overlay        from '@stateless/atomic/Overlay';

import styles from './styles.module.scss';

const propTypes = {
    appSetting:          PropTypes.object,
    onClose:             PropTypes.func,
    onLogoutClicked:     PropTypes.func,
    onSoldOutClicked:    PropTypes.func,
    onWorkplaceSelected: PropTypes.func,
    open:                PropTypes.bool,
    selectedWorkplace:   PropTypes.oneOfObjectKeys(Workplace),
    setDeliveryEnabled:  PropTypes.func,
    setShowServedOrders: PropTypes.func,
    showServedOrders:    PropTypes.bool,
    soldOutTill:         PropTypes.string,
};

const MenuOverlay = ({
    onClose             = _.noop,
    open                = false,
    onWorkplaceSelected = _.noop,
    selectedWorkplace   = null,
    appSetting          = null,
    setDeliveryEnabled  = _.noop,
    showServedOrders    = false,
    setShowServedOrders = _.noop,
    onLogoutClicked     = _.noop,
    onSoldOutClicked    = _.noop,
    soldOutTill         = '',
}) => {
    const translator             = new Translator('components.menuOverlay.');
    const deliveryEnabled        = _.get(appSetting, 'deliveryEnabled', false);
    const currentlySoldOut       = DateTimeHelper.isOnOrBeforeDay(soldOutTill, new Date());
    const deliveryHeaderText     = deliveryEnabled ?
        translator.translate('deliveryEnabled') :
        translator.translate('deliveryDisabled');
    const servedOrdersHeaderText = showServedOrders ?
        translator.translate('servedOrdersShown') :
        translator.translate('servedOrdersHidden');
    const soldOutHeaderText      = currentlySoldOut ?
        translator.translate('soldOutTodayHeader') :
        translator.translate('notSoldOutTodayHeader');

    function renderWorkPlaceButtons() {
        return _.map(Object.keys(Workplace), (workplace) => {
            function onClick() {
                onWorkplaceSelected(workplace);
            }

            const buttonColor = selectedWorkplace === workplace ?
                ButtonColor.black :
                ButtonColor.gray;
            const iconType    = workplace === Workplace.kitchen ?
                IconType.pizza :
                IconType.cash;

            return (
                <Button
                    key={workplace}
                    type={ButtonType.pill}
                    text={I18n.t(workplace)}
                    color={buttonColor}
                    iconLeft={iconType}
                    onClick={onClick}
                />
            );
        });
    }

    function renderDeliveryButton() {
        const buttonColor = deliveryEnabled ?
            ButtonColor.gray :
            ButtonColor.black;
        const buttonText  = deliveryEnabled ?
            translator.translate('disableDelivery') :
            translator.translate('enableDelivery');

        function onClick() {
            setDeliveryEnabled();
        }

        return (
            <Button
                type={ButtonType.pill}
                text={buttonText}
                color={buttonColor}
                iconLeft={IconType.delivery}
                onClick={onClick}
            />
        );
    }

    function renderShowServedOrdersButton() {
        const buttonColor = showServedOrders ?
            ButtonColor.gray :
            ButtonColor.black;
        const buttonText  = showServedOrders ?
            translator.translate('hideServedOrders') :
            translator.translate('showServedOrders');

        function onClick() {
            setShowServedOrders(!showServedOrders);
        }

        return (
            <Button
                type={ButtonType.pill}
                text={buttonText}
                color={buttonColor}
                iconLeft={IconType.checkout}
                onClick={onClick}
            />
        );
    }

    function renderLogoutButton() {
        return (
            <Button
                type={ButtonType.pill}
                text={I18n.t('logout')}
                color={ButtonColor.black}
                iconLeft={IconType.cross}
                onClick={onLogoutClicked}
            />
        );
    }

    function renderSoldOutButton() {
        const buttonText          = currentlySoldOut ?
            translator.translate('setNotSoldOutToday') :
            translator.translate('setSoldOutToday');
        const iconType            = currentlySoldOut ?
            IconType.cross :
            IconType.pizza;
        const buttonColor         = currentlySoldOut ?
            ButtonColor.black :
            ButtonColor.gray;
        const soldOutCLickedValue = currentlySoldOut ?
            null :
            new Date();

        return (
            <Button
                type={ButtonType.pill}
                text={buttonText}
                color={buttonColor}
                iconLeft={iconType}
                onClick={onSoldOutClicked(soldOutCLickedValue)}
            />
        );
    }

    return (
        <Overlay
            onCloseClick={onClose}
            open={open}
        >
            <div className={styles.menuOverlay}>
                <MenuItem headerText={translator.translate('workingPlace')}>
                    {renderWorkPlaceButtons()}
                </MenuItem>
                <MenuItem headerText={deliveryHeaderText}>
                    {renderDeliveryButton()}
                </MenuItem>
                <MenuItem headerText={servedOrdersHeaderText}>
                    {renderShowServedOrdersButton()}
                </MenuItem>
                <MenuItem headerText={soldOutHeaderText}>
                    {renderSoldOutButton()}
                </MenuItem>
                <MenuItem>
                    {renderLogoutButton()}
                </MenuItem>
            </div>
        </Overlay>
    );
};

MenuOverlay.propTypes = propTypes;

export default MenuOverlay;
