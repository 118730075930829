//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Header                     from '@connected/Header';
import DateHelper                 from '@helper/Date';
import OrderProvider              from '@helper/OrderProvider';
import { OrderProductsActions }   from '@slices/orderProducts';
import { OrdersActions }          from '@slices/orders';
import ContentWrapper             from '@stateless/atomic/ContentWrapper';
import OrderListItem              from '@stateless/atomic/OrderListItem';
import PageTitle                  from '@stateless/composed/PageTitle';
import { selectOrders }           from '@store/selectors/orders';
import { selectOrderDate }        from '@store/selectors/orders';
import { selectShowServedOrders } from '@store/selectors/orders';
import { selectWorkplace }        from '@store/selectors/user';

import styles from './styles.module.scss';

const Home = () => {
    const orders                                  = useSelector(selectOrders);
    const dispatch                                = useDispatch();
    const selectedWorkplace                       = useSelector(selectWorkplace);
    const selectedDate                            = useSelector(selectOrderDate);
    const showServedOrders                        = useSelector(selectShowServedOrders);
    const [extendedOrderIri, setExtendedOrderIri] = React.useState(null);
    const isToday                                 = useMemo(() => {
        return DateHelper.isToday(selectedDate);
    }, [selectedDate]);
    const preparedOrders                          = OrderProvider.getFilteredAndSortedOrders(
        orders,
        selectedWorkplace,
        showServedOrders,
    );

    function updateOrder(iri, values) {
        dispatch(OrdersActions.updateOrder({
            iri,
            values,
        }));
    }

    function updateOrderProductStatus(orderIri, orderProductIri, status) {
        dispatch(OrderProductsActions.updateOrderProductStatus({
            orderIri,
            orderProductIri,
            status,
        }));
    }

    function renderOrderListItems() {
        return _.map(preparedOrders, (order) => {
            const extended = extendedOrderIri === order.iri;

            function extendOrderClicked() {
                if (extendedOrderIri === order.iri) {
                    setExtendedOrderIri(null);

                    return;
                }

                setExtendedOrderIri(order.iri);
            }

            return (
                <OrderListItem
                    key={order.iri}
                    isToday={isToday}
                    updateOrder={updateOrder}
                    updateOrderProductStatus={updateOrderProductStatus}
                    order={order}
                    workplace={selectedWorkplace}
                    extended={extended}
                    onOrderClicked={extendOrderClicked}
                />
            );
        });
    }

    return (
        <>
            <PageTitle title={I18n.t('home')} />
            <Header />
            <ContentWrapper>
                <div className={styles.homeContent}>
                    {renderOrderListItems()}
                </div>
            </ContentWrapper>
        </>
    );
};

export default Home;
