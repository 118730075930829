//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import _              from 'lodash';
import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import * as Api               from '@api';
import Hydra                  from '@helper/Hydra';
import { RestaurantsActions } from '@slices/restaurants';

function* fetchRestaurants(action) {
    const response = yield call(Api.context.restaurants.fetch);

    if (response.ok) {
        const restaurants = Hydra.getMembersFromResponse(response.data);

        yield put(RestaurantsActions.fetchRestaurantsSucceeded({
            restaurants,
        }));
    } else {
        yield put(RestaurantsActions.fetchRestaurantsFailed());
    }
}

function* fetchRestaurantsSucceeded(action) {
    const restaurants = yield select((state) => _.get(state, 'restaurants.restaurants'));

    if (restaurants.length > 0) {
        const singleRestaurant = restaurants[0];

        yield put(RestaurantsActions.setCurrentRestaurant({
            ...singleRestaurant,
        }));
    }
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([RestaurantsActions.fetchRestaurants().type],          fetchRestaurants),
        takeLatest([RestaurantsActions.fetchRestaurantsSucceeded().type], fetchRestaurantsSucceeded),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
