//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

export default (api) => {
    return {
        get:    (iri) => {
            return api.get(iri);
        },
        fetch:  (date) => {
            return api.get(ApiUrls.API_ORDERS, {
                date,
            });
        },
        update: (iri, body) => {
            return api.put(iri, body);
        },
    };
};
